<template>
  <div>
    <titulo-docentes-periodo />

    <hr class="my-3" />
    <strong class="d-block my-4">Dados da turma</strong>

    <div class="row">
      <div class="col-12 col-md-4">
        <input-select-search
          ref="anoLetivo"
          v-model="anoLetivoId"
          :label="$t('ACADEMICO.ANO_LETIVO')"
          placeholder="GERAL.DIGITE_OU_PROCURE"
          :options="opcoes.anoLetivo"
          required
        />
      </div>
      <div class="col-12 col-md-4">
        <input-select-search
          ref="turma"
          v-model="turmaId"
          :label="$t('ACADEMICO.TURMAS')"
          placeholder="GERAL.DIGITE_OU_PROCURE"
          :options="opcoes.turma"
          :isDisabled="desabilitados.turma"
          required
        />
      </div>
      <div class="col-12 col-md-4">
        <input-select-multiple
          ref="disciplinas"
          :label="$t('ACADEMICO.DISCIPLINAS')"
          v-model="disciplinaParaVinculo"
          placeholder="GERAL.DIGITE_OU_PROCURE"
          :options="opcoes.disciplinasFiltradas"
          :disabled="desabilitados.disciplina"
          required
        />
      </div>
    </div>

    <hr class="my-3" />
    <!-- DOCENTES -->
    <strong class="d-block mb-4">Adicionar docentes a turma</strong>
    <div class="row pb-2">
      <div class="col-12 col-md-4">
        <input-select-search
          :label="$t('ACADEMICO.DOCENTES')"
          placeholder="GERAL.DIGITE_OU_PROCURE"
          :options="opcoes.docente"
          v-model="docenteId"
          :isDisabled="desabilitados.docente"
          required
        />
      </div>
      <div class="col-12 col-md-auto my-auto">
        <b-button
          class="w-100 btn-bootstrap-ajuste-mt"
          variant="primary"
          @click="adicionarDocente"
          :disabled="desabilitados.btnAdicionarDocente"
        >
          {{ $t('ACADEMICO.ADICIONAR_DOCENTE') }}
        </b-button>
      </div>
    </div>

    <tabela-docente :items="tabelaDocente" @remover="excluir" />
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
import rotas from '@/common/utils/rotas';

// Services:
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';
import PessoaService from '@/common/services/pessoa/pessoa.service';
import TurmasService from '@/common/services/academico/turma.service';

// Components:
import TabelaDocente from './components/TabelaDocente.vue';
import { InputSelectSearch, InputSelectMultiple } from '@/components/inputs';
import TituloDocentesPeriodo from '@/views/academico/docentes-periodo/components/TituloDocentesPeriodo';

export default {
  components: {
    InputSelectSearch,
    TabelaDocente,
    InputSelectMultiple,
    TituloDocentesPeriodo,
  },
  mounted() {
    this.getAnosLetivos();
    this.getDocentes();
  },
  data() {
    return {
      opcoes: {
        anoLetivo: [],
        turma: [],
        docente: [],
        disciplinasFiltradas: [],
      },
      docentes: [],
      tabelaDocente: [],
      disciplinas: [],
      disciplinaParaVinculo: '',
      anoLetivoId: '',
      turmaId: '',
      docenteId: '',
      desabilitados: {
        turma: true,
        disciplina: true,
        docente: true,
        btnAdicionarDocente: true,
      },
    };
  },
  watch: {
    anoLetivoId: {
      handler: 'getTurma',
      immediate: true,
    },
    turmaId: {
      handler: 'filtrarDisciplinasPorTurma',
      immediate: true,
    },
    disciplinaParaVinculo: {
      handler: 'getDocentesIncluidosNoPeriodo',
      immediate: true,
    },
    docenteId: {
      handler: 'habilitarBtnDocente',
    },
  },
  methods: {
    // Funções formulário
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },

    habilitarCampo(campo) {
      this.desabilitados[campo] = false;
    },

    desabilitarCampo(campo) {
      this.desabilitados[campo] = true;
    },

    habilitarBtnDocente() {
      if (this.docenteId) this.desabilitados.btnAdicionarDocente = false;
    },

    excluir(item) {
      mensagem
        .confirmacao(null, this.$t(`Deseja remover docente ${item.item.nome}?`))
        .then(async (result) => {
          if (result.value) {
            if (item.item.anoLetivoDocenteId) {
              await this.remover(item);
            }
          }
        });
    },

    // funções obter dados
    getAnosLetivos() {
      this.$store.dispatch(START_LOADING);
      this.desabilitarCampo('btnAdicionarDocente');
      this.desabilitarCampo('docente');
      this.desabilitarCampo('turma');
      AnoLetivoService.listar()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...data,
            text: row.ano,
            value: row.id,
          }));

          this.opcoes.anoLetivo = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getTurma(id) {
      if (!id) return;
      this.turmaId = '';
      this.tabelaDocente = [];
      this.desabilitarCampo('disciplina');
      this.desabilitarCampo('btnAdicionarDocente');
      this.desabilitarCampo('docente');
      this.$store.dispatch(START_LOADING);
      TurmasService.buscarPorAnoLetivoId(id)
        .then(({ data }) => {
          this.disciplinas = data;
          if (data.length == 0) {
            data = [
              {
                value: null,
                text: 'Não existem turmas para o ano selecionado',
              },
            ];
          } else {
            data = data.map((row) => ({
              ...data,
              text: row.descricao,
              value: row.id,
            }));
          }
          this.opcoes.turma = data;
          this.habilitarCampo('turma');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getDocentes() {
      this.desabilitarCampo('btnAdicionarDocente');
      this.$store.dispatch(START_LOADING);
      PessoaService.listarDocentes()
        .then(({ data }) => {
          this.docentes = data;
          data = data.map((row) => ({
            ...data,
            text: row.nome,
            value: row.id,
          }));
          this.opcoes.docente = this.filtrarDocentes(data);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getDocentesIncluidosNoPeriodo() {
      this.docenteId = null;
      if (this.disciplinaParaVinculo === null) return;
      this.$store.dispatch(START_LOADING);
      PessoaService.listarDocentesPorFiltro({
        disciplinaId: this.disciplinaParaVinculo?.disciplina.id,
        anoLetivoId: this.anoLetivoId,
        turmaId: this.turmaId,
      })
        .then(({ data }) => {
          this.tabelaDocente = data;
          this.habilitarBtnDocente('btnAdicionarDocente');
          this.habilitarCampo('docente');
          this.getDocentes();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // Funções filtrar dados
    filtrarDisciplinasPorTurma() {
      this.disciplinaParaVinculo = null;
      if (this.turmaId === '') return;
      this.desabilitarCampo('docente');

      const turmaSelecionada = this.disciplinas.filter(
        ({ id }) => id === this.turmaId
      );

      if (turmaSelecionada[0]?.disciplinas?.length === 0) {
        this.opcoes.disciplinasFiltradas = [
          {
            value: null,
            text: 'Sem disciplinas para turma selecionada',
          },
        ];
      } else {
        this.opcoes.disciplinasFiltradas =
          turmaSelecionada[0]?.disciplinas?.map((item) => ({
            ...item,
            value: item,
            text: `${item.codigo} - ${item.disciplinaDescricao}`,
          }));
      }
      this.habilitarCampo('disciplina');
    },

    filtrarDocentes(data) {
      let docentesFiltrados = [];
      data.forEach((item, index) => {
        let resultado = [];
        this.tabelaDocente.forEach((item2) => {
          if (item2.id === item.value) {
            resultado.push({ ...item, index });
          }
        });
        if (index !== resultado[0]?.index) {
          docentesFiltrados.push(item);
        }
      });
      return docentesFiltrados;
    },

    // funções crud
    async remover(item) {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.removerDocente(item.item)
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
          this.getDocentesIncluidosNoPeriodo();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    adicionarDocente() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.adicionarDocente({
        disciplinaId: this.disciplinaParaVinculo?.disciplina.id,
        anoLetivoId: this.anoLetivoId,
        turmaId: this.turmaId,
        pessoaId: this.docenteId,
      })
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
          this.docenteId = '';
          this.desabilitarCampo('btnAdicionarDocente');
          this.getDocentesIncluidosNoPeriodo();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // funções navegação
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
