<template>
  <div class="mb-4">
    <b-table
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      :fields="tabela.fields"
      :items="items"
      responsive="sm"
      show-empty
      striped
    >
      <template #cell(acoes)="item">
        <div class="flex">
          <b-dropdown variant="outline-default">
            <b-dropdown-item @click="remover(item)">
              {{ $t('GERAL.EXCLUIR') }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      tabela: {
        fields: [
          {
            key: 'codigo',
            label: this.$t('GERAL.CODIGO'),
            sortable: true,
          },
          {
            key: 'nome',
            label: this.$t('GERAL.NOME'),
            sortable: true,
          },
          {
            key: 'acoes',
            label: this.$t('GERAL.ACOES'),
          },
        ],
      },
    };
  },
  methods: {
    remover(item) {
      this.$emit('remover', item);
    },
  },
};
</script>
